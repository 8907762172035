<template>
    <base-sezione models-name="errori">

        <template #table="{ isLoading, onChangeOptions }">
            <errori-table :is-loading="isLoading" @changeOptions="onChangeOptions"></errori-table>
        </template>

    </base-sezione>
</template>

<script>
    import BaseSezione from '@/components/aaaProject/commons/BaseSezione';
    import ErroriTable from '@/components/secure/errori/components/ErroriTable';

    export default {
        name: 'Errori',
        components: {ErroriTable, BaseSezione}
    }
</script>

<style scoped>

</style>
