<template>
    <v-dialog v-model="dialog"
              persistent
              scrollable
              max-width="800px"
              @click:outside.stop="!isLoading && $emit('close')"
              @keydown.esc="!isLoading && $emit('close')"
    >
        <v-card>

            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click.stop="$emit('close')">
                    <v-icon>fa-times</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-subtitle>
                <h-row justify="center">
                    <h-col cols="auto" no-padding>
                        <h2>Stacktree</h2>
                    </h-col>
                </h-row>
            </v-card-subtitle>

            <v-divider></v-divider>

            <v-card-text class="mt-6">
                <h-row justify="center">
                    <h-col>
                        <v-textarea outlined
                                    v-model="stacktree"
                                    auto-grow
                                    readonly
                                    :disabled="isLoading"
                        ></v-textarea>
                    </h-col>
                </h-row>
            </v-card-text>

        </v-card>
    </v-dialog>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';

    export default {
        name: 'StackTreeDialog',
        components: {HRow, HCol},
        props: {dialog: Object},
        data: () => ({
            isLoading: false,
            apiTimer: null,
            stacktree: ''
        }),
        computed: {
            isGlobalLoading: function () {
                return this.$store.state.servicesModule.isGlobalLoading;
            }
        },
        methods: {
            loadStacktree() {
                function doRequest(_this) {
                    if (!_this.isGlobalLoading) {
                        window.clearInterval(_this.apiTimer);

                        //GET dello stacktree
                        _this.$store.dispatch('defaultRequest', {
                            api: 'GET_ERRORI',
                            paylod: _this.dialog.ID_REC,
                            doResponse: model => {
                                _this.dialog.DATA_LETTURA = model.DATA_LETTURA;
                                _this.stacktree = model.STACKTREE;
                            },
                            doClose: () => _this.isLoading = false
                        });
                    }
                }

                this.isLoading = true;
                this.stacktree = '';
                this.apiTimer = window.setInterval(() => doRequest(this), 1000);
                doRequest(this);
            }
        },
        created() {
            this.loadStacktree()
        },
        beforeDestroy() {
            window.clearInterval(this.apiTimer);
        }
    }
</script>

<style scoped>

</style>
