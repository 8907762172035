var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h-row',[_c('h-col',{attrs:{"cols":"12","sm":"6"}},[_c('base-table-header-select',{attrs:{"label":"Codice Errore","key-options":"status","items":[
                                      {label: '--Nessun Filtro--', value: null},
                                      {label: '400', value: 400},
                                      {label: '401', value: 401},
                                      {label: '403', value: 403},
                                      {label: '419', value: 419},
                                      {label: '420', value: 420},
                                      {label: '421', value: 421},
                                      {label: '500', value: 500},
                                      {label: '600', value: 600}
                                  ]},on:{"changeOptions":function($event){return _vm.$emit('changeOptions', $event)}}})],1),_c('h-col',{attrs:{"cols":"12","sm":"6"}},[_c('base-table-header-filter',{on:{"changeFilter":function($event){return _vm.$emit('changeFilter', $event)},"changeOptions":function($event){return _vm.$emit('changeOptions', $event)}}})],1),_c('h-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('base-data-field',{attrs:{"label":"File","append-icon":"fa-calendar-alt"},model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}})],1),_c('h-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('button-tooltip',{attrs:{"block":"","color":"primary","disabled":_vm.isGlobalLoading,"text":"SCARICA","tooltip":"Click per scaricare il file di log"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadLog.apply(null, arguments)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }