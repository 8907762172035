import { render, staticRenderFns } from "./Errori.vue?vue&type=template&id=a71eb958&scoped=true&"
import script from "./Errori.vue?vue&type=script&lang=js&"
export * from "./Errori.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a71eb958",
  null
  
)

export default component.exports