<template>
    <h-row>
        <h-col cols="12" sm="6">
            <base-table-header-select label="Codice Errore"
                                      key-options="status"
                                      :items="[
                                          {label: '--Nessun Filtro--', value: null},
                                          {label: '400', value: 400},
                                          {label: '401', value: 401},
                                          {label: '403', value: 403},
                                          {label: '419', value: 419},
                                          {label: '420', value: 420},
                                          {label: '421', value: 421},
                                          {label: '500', value: 500},
                                          {label: '600', value: 600}
                                      ]"
                                      @changeOptions="$emit('changeOptions', $event)"
            ></base-table-header-select>
        </h-col>
        <h-col cols="12" sm="6">
            <base-table-header-filter @changeFilter="$emit('changeFilter', $event)"
                                      @changeOptions="$emit('changeOptions', $event)"
            ></base-table-header-filter>
        </h-col>
        <h-col cols="12" class="pb-0">
            <base-data-field v-model="data"
                             label="File"
                             append-icon="fa-calendar-alt"
            ></base-data-field>
        </h-col>
        <h-col cols="12" class="pt-0">
            <button-tooltip block
                            color="primary"
                            :disabled="isGlobalLoading"
                            text="SCARICA"
                            tooltip="Click per scaricare il file di log"
                            @click.stop="downloadLog"
            ></button-tooltip>
        </h-col>
    </h-row>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseTableHeaderSelect from '@/components/aaaProject/table/BaseTableHeaderSelect';
    import BaseTableHeaderFilter from '@/components/aaaProject/table/BaseTableHeaderFilter';
    import BaseDataField from '@/components/aaaGenerics/form/BaseDataField';
    import ButtonTooltip from '@/components/aaaGenerics/commons/ButtonTooltip';

    export default {
        name: 'ErroriTableHeader',
        components: {HRow, HCol, ButtonTooltip, BaseDataField, BaseTableHeaderFilter, BaseTableHeaderSelect},
        data: () => ({
            data: null
        }),
        computed: {
            isGlobalLoading: function () {
                return this.$store.state.servicesModule.isGlobalLoading;
            }
        },
        methods: {
            downloadLog() {
                function doRequest(_this) {
                    if (!_this.isGlobalLoading) {
                        window.clearInterval(_this.apiTimer);

                        //GET del file di log
                        _this.$store.dispatch('defaultRequest', {
                            api: 'GET_ERRORI_LOG',
                            paylod: _this.data + 'T00:00:00.000Z',
                            doResponse: file => _this.$store.dispatch('downlaodToFile', {
                                data: file, filename: _this.data + '.log'
                            }),
                            doClose: () => {
                            }
                        });
                    }
                }

                this.apiTimer = window.setInterval(() => doRequest(this), 1000);
                doRequest(this);

            }
        },
        created() {
            this.data = new Date().toISOString().slice(0, 10);
        }
    }
</script>

<style scoped>

</style>
