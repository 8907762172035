<template>
    <base-table models-name="errori"
                :is-loading="isLoading"
                :headers="headers"
                @changeOptions="onChangeOptions($event)"
    >

        <template #header="{ onChangeFilter }">
            <errori-table-header @changeOptions="onChangeOptions($event)"
                                 @changeFilter="onChangeFilter"
            ></errori-table-header>
        </template>

        <template #DATA_LETTURA="{ item }">
            <button-tooltip small
                            icon
                            :icon-name="'fa-' + (item.DATA_LETTURA ? 'eye' : 'eye-slash')"
                            :color="item.DATA_LETTURA ? 'success' : 'error'"
                            :disabled="isLoading"
                            :tooltip="'Errore ' + (item.DATA_LETTURA ? 'letto il ' + getData(item.DATA_LETTURA) : 'da leggere')"
            ></button-tooltip>
        </template>

        <template #REQUEST_METHOD="{ item }">
            <v-btn text :color="getColorMethod(item.REQUEST_METHOD)">
                <span>{{ item.REQUEST_METHOD }}</span>
            </v-btn>
        </template>

        <template #REQUEST_URI="{ item }">
            <i style="word-break: break-all; cursor: pointer;" @click.stop="share(item.REQUEST_URI)">
                {{ item.REQUEST_URI }}
            </i>
        </template>

        <template #CREATOR_TIMESTAMP="{ item }">
            <span>{{ getData(item.CREATOR_TIMESTAMP) }}</span>
        </template>

        <template #info="{ item }">
            <button-tooltip small
                            text="VISUALIZZA"
                            color="primary"
                            tooltip="Click per visualizzare lo stacktree dell'errore"
                            @click.stop="dialog = item"
            ></button-tooltip>
        </template>

        <template #dialog>
            <stack-tree-dialog v-if="Boolean(dialog)"
                               :dialog="dialog"
                               @close="dialog = false"
            ></stack-tree-dialog>
        </template>

    </base-table>

</template>

<script>
    import BaseTable from '@/components/aaaProject/table/BaseTable';
    import ErroriTableHeader from '@/components/secure/errori/components/ErroriTableHeader';
    import ButtonTooltip from '@/components/aaaGenerics/commons/ButtonTooltip';
    import StackTreeDialog from '@/components/secure/errori/components/StackTreeDialog';
    import moment from '@/moment';

    export default {
        name: 'ErroriTable',
        components: {StackTreeDialog, ErroriTableHeader, ButtonTooltip, BaseTable},
        props: {isLoading: Boolean},
        data: () => ({
            dialog: false,
            headers: [
                {
                    text: '#',
                    value: 'DATA_LETTURA',
                    align: 'center',
                    custom: true
                },
                {
                    text: 'Status ',
                    value: 'STATUS',
                    align: 'center'
                },
                {
                    text: 'Request Method ',
                    value: 'REQUEST_METHOD',
                    align: 'center',
                    custom: true
                },
                {
                    text: 'Request URI ',
                    value: 'REQUEST_URI',
                    custom: true
                },
                {
                    text: 'Data ',
                    value: 'CREATOR_TIMESTAMP',
                    align: 'center',
                    custom: true
                },
                {
                    text: 'Utente ',
                    value: 'CREATOR',
                    align: 'center'
                },
                {
                    value: 'info',
                    align: 'center',
                    sortable: false,
                    custom: true
                }
            ]
        }),
        methods: {
            onChangeOptions(options) {
                this.$emit('changeOptions', options);
            },
            getColorMethod(requestMethod) {

                switch (requestMethod) {
                    case 'GET':
                        return 'success';
                    case 'POST':
                        return 'warning'
                    case 'PUT':
                        return 'primary';
                    case 'DELETE':
                        return 'error';
                    default:
                        return null;
                }

            },
            share(value) {
                this.$store.dispatch('copyToClipboard', {
                    value,
                    text: 'Link copiato negli appunti'
                });
            },
            getData(data) {
                return moment.toDate(data);
            }
        }
    }
</script>

<style scoped>

</style>
