var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',{attrs:{"models-name":"errori","is-loading":_vm.isLoading,"headers":_vm.headers},on:{"changeOptions":function($event){return _vm.onChangeOptions($event)}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var onChangeFilter = ref.onChangeFilter;
return [_c('errori-table-header',{on:{"changeOptions":function($event){return _vm.onChangeOptions($event)},"changeFilter":onChangeFilter}})]}},{key:"DATA_LETTURA",fn:function(ref){
var item = ref.item;
return [_c('button-tooltip',{attrs:{"small":"","icon":"","icon-name":'fa-' + (item.DATA_LETTURA ? 'eye' : 'eye-slash'),"color":item.DATA_LETTURA ? 'success' : 'error',"disabled":_vm.isLoading,"tooltip":'Errore ' + (item.DATA_LETTURA ? 'letto il ' + _vm.getData(item.DATA_LETTURA) : 'da leggere')}})]}},{key:"REQUEST_METHOD",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":_vm.getColorMethod(item.REQUEST_METHOD)}},[_c('span',[_vm._v(_vm._s(item.REQUEST_METHOD))])])]}},{key:"REQUEST_URI",fn:function(ref){
var item = ref.item;
return [_c('i',{staticStyle:{"word-break":"break-all","cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.share(item.REQUEST_URI)}}},[_vm._v(" "+_vm._s(item.REQUEST_URI)+" ")])]}},{key:"CREATOR_TIMESTAMP",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getData(item.CREATOR_TIMESTAMP)))])]}},{key:"info",fn:function(ref){
var item = ref.item;
return [_c('button-tooltip',{attrs:{"small":"","text":"VISUALIZZA","color":"primary","tooltip":"Click per visualizzare lo stacktree dell'errore"},on:{"click":function($event){$event.stopPropagation();_vm.dialog = item}}})]}},{key:"dialog",fn:function(){return [(Boolean(_vm.dialog))?_c('stack-tree-dialog',{attrs:{"dialog":_vm.dialog},on:{"close":function($event){_vm.dialog = false}}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }